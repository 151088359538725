import img01 from "../assets/images/olympo36/img01.jpg"
import img02 from "../assets/images/olympo36/img02.jpg"
import img03 from "../assets/images/olympo36/img03.jpg"
import img04 from "../assets/images/olympo36/img04.jpg"
import img05 from "../assets/images/olympo36/img05.jpg"
import img06 from "../assets/images/olympo36/img06.jpg"
import img08 from "../assets/images/olympo36/img08.jpg"
import img09 from "../assets/images/olympo36/img09.jpg"
import img10 from "../assets/images/olympo36/img10.jpg"
import img11 from "../assets/images/olympo36/img11.jpg"
import img13 from "../assets/images/olympo36/img13.jpg"
import img14 from "../assets/images/olympo36/img14.jpg"
import img15 from "../assets/images/olympo36/img15.jpg"
import img17 from "../assets/images/olympo36/img17.jpg"
import img18 from "../assets/images/olympo36/img18.jpg"
export const OlympoBlock1 = [
  {
    title: `ACABADO TECHO`,
    text: `El techo del salón de pasajeros es decorado con material PVC DECORATIVO que es impermeable, de facil limpieza, antinflamable y aislante térmico.`,
    img: img01,
  },
  {
    title: `ACABADO LATERALES`,
    text: ` Decorado con material PVC DECORATIVO que es impermeable, de facil limpieza, antinflamable y aislante térmico. Perfilado de marcos de ventanas con PVC DECORATIVO de brillo natural.`,
    img: img02,
  },
  {
    title: `ACABADO DE PISO`,
    text: `El piso es cubierto en PVC FLEXIBLE DE ALTO TRÁNSITO de alta resistencia al desgaste. Opciones de colores para el decorado del piso. Es antinflamable y retardante.`,
    img: img03,
  },
  {
    title: `ILUMINACIÓN`,
    text: ` Iluminación con tecnología LED a ambos lados del salón en la parte superior (techo).`,
    img: img04,
  },
  {
    title: `PAQUETERAS`,
    text: ` LIGERAS y elaboradas en poliuretano rigido de alta densidad. Fijadas en el techo estratégicamente para EVITAR VIBRACIONES. Luces de lectura individuales con iluminación independiente. `,
    img: img05,
    textAlt: `*alternativa personalizable`,
  },
]
export const OlympoBlock2 = [
  {
    title: `CÚPULA`,
    text: `Gravado natural con lineas estelizadas decorativas y definición de contornos para la colocación de luces de cabina, parlantes, entre otros.  Iluminación con sistema LED.`,
    img: img06,
  },
  
  {
    title: `TABLERO DE CONTROL`,
    text: `Diseñado para FACILITAR LA OPERACIÓN de los diferentes mandos de control de carrocería y chasis; diseño AERODINÁMICO. Fabricado en fibra de vidrio. Protección externa con poliuretano.`,
    img: img08,
  },
  {
    title: `GRADA DE INGRESO`,
    text: `Con ILUMINACIÓN PROPIA y fabricado en fibra de vidrio. Base estructural reforzado. `,
    img: img09,
  },
]
export const OlympoBlock3 = [
  {
    title: `FRONTAL`,
    text: ` Modelo AERODINÁMICO. Laminado en fibra de vidrio con refuerzo interno para reducción de daños ocasionados por impactos. Luces LED bifocales con efecto Flash y decorativos con iluminión neon.`,
    img: img10,
  },
  {
    title: `POSTERIOR`,
    text: ` Modelo AERODINÁMICO. Laminado en fibra de vidrio con refuerzo interno para reducción de daños ocasionados por impactos. Luces LED bifocales con efecto Flash y decorativos con iluminión neon.`,
    img: img11,
  }
]
export const OlympoBlock4 = [
  {
    title: `CALEFACCIÓN`,
    text: `Sistema de concentradores de calor y convección forzada. Consta de radiadores y aletas de aluminio distribuidos en todo el salón de pasajeros; sopladores eléctricos silenciosos, ubicados estrategicamente para un flujo homogeneo del calor en todo el salón de pasajeros. `,
    img: img13,
    textAlt: `*alternativa personalizable`,
  },
  {
    title: `ASIENTOS`,
    text: [
      {
        titulo: "Asiento SOFT",
        text: `Diseño ergonómico, suavidad en la reclinación y variedad de materiales y colores.
      Inyectados en poliuretano flexible con densidad de acuerdo al tipo de servicio. Precisión en el armado para evitar vibraciones y uso de componentes de amortiguación.`,
      }
    ],
    textAlt: `*alternativa personalizable`,
    img: img14,
  },
  {
    title: `AIRE ACONDICIONADO`,
    text: `Sistema que intercambia el aire concentrado por aire fresco del medio ambiente, purificado y climatizado a una determinada temperatura, asegurando un aire de calidad y  garantizando comodidad y bienestar a los pasajeros.`,
    textAlt: `*alternativa personalizable`,
    img: img15,
  }
]
export const OlympoBlock5=[
  {
    title:"ESPEJOS RETROVISORES",
    text:'Eléctricos con mando selector que regulan el angulo de visión del conductor. Cuenta con espejos calefactables para mejorar la visibilidad de conducción con su desempañado en zonas humedas.',
    img:img17
  },
  {
    title:'ACCIONAMIENTO PUERTA SERVICIO',
    text:': El accionamiento de la puerta se realiza con la instalación de una comprensora eléctrica que deposita aire comprimido en un tanque especial.',
    img:img18
  }
]